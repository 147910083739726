import React from "react";
import ContactTeam from "../Components/ContactTeam/ContactTeam";
import ReqFinancing from "../Components/ReqFinancing/ReqFinancing";
import { Container, MainSubTitle, MainTitle } from "../Style";

const ContactUs = () => {
  return (
    <>
      <ContactTeam />
      <ReqFinancing
        ReqFTitle="Set up a Time for a Strategy Session."
        ReqFP='"From No Credit to Bright Investments: Your Pathway to Financial Growth Begins Here."'
      />
      <Container>
        <MainTitle
          data-aos="fade-up"
          data-aos-duration="3000"
          style={{ fontSize: "30px" }}
        >
          Thank you for Submitting!
        </MainTitle>
        <MainSubTitle
          data-aos="fade-up"
          data-aos-duration="3000"
          style={{ margin: "inherit", maxWidth: "100%" }}
        >
        </MainSubTitle>
      </Container>
    </>
  );
};

export default ContactUs;
