import React from "react";
import "../Navbar/Navbar.css";
import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Container, Button } from "../../Style";
import { ScrollTop } from "../ScrollTop";

import logo from "../../images/scalekit-main.svg";
import Smalllogo from "../../images/LogoMain.svg";

const Nav = () => {
  // Sticky Fuction start
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Page active in Navbar
  const location = useLocation();

  return (
    <>
      <nav className={`navbar navbar-expand-lg ${isSticky ? "sticky" : ""}`}>
        <Container>
          <NavLink className="navbar-brand" to="/">
            <img className="BigLogo" src={logo} alt="" />
            <img className="SmallLogo" src={Smalllogo} alt="" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto mb-2 mb-lg-0">
              <li className={location.pathname === "/" ? "active" : ""}>
                <NavLink className="nav-link" onClick={ScrollTop} to={"/"}>
                  Home
                </NavLink>
              </li>
              <li className={location.pathname === "/about" ? "active" : ""}>
                <NavLink className="nav-link" onClick={ScrollTop} to={"/about"}>
                  About
                </NavLink>
              </li>
              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Services
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <NavLink
                      onClick={ScrollTop}
                      className="dropdown-item"
                      to={"/businessfunding"}
                    >
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_1084_9943)">
                          <path
                            d="M28.9091 5.90918H10.2727C10.0909 5.90918 10 6.04554 10 6.22736V16.6819C10 16.8637 10.1364 17.0001 10.2727 17.0001H28.9091C29.0909 17.0001 29.1818 16.8637 29.1818 16.6819V6.22736C29.1818 6.04554 29.0455 5.90918 28.9091 5.90918ZM27.7727 14.4092C27.1818 14.4092 26.6818 14.9092 26.6818 15.5001H12.4545C12.4545 14.9092 11.9545 14.4092 11.3636 14.4092V8.54554C11.9545 8.54554 12.4545 8.04554 12.4545 7.45463H26.6364C26.6364 8.04554 27.1364 8.54554 27.7273 8.54554V14.4092H27.7727Z"
                            fill="#D3D6DE"
                          />
                          <path
                            d="M28.9094 17.409H10.2731C9.90945 17.409 9.63672 17.1363 9.63672 16.7726V6.22718C9.63672 5.86355 9.90945 5.59082 10.2731 5.59082H28.9094C29.2731 5.59082 29.5458 5.86355 29.5458 6.22718V16.7726C29.5458 17.0908 29.2276 17.409 28.9094 17.409ZM10.3185 16.7272H28.864V6.27264H10.3185V16.7272ZM26.6822 15.8181H12.4549C12.2731 15.8181 12.0913 15.6817 12.0913 15.4545C12.0913 15.0454 11.7731 14.7272 11.364 14.7272C11.1822 14.7272 11.0458 14.5908 11.0458 14.3635V8.54537C11.0458 8.36355 11.1822 8.22718 11.364 8.18173C11.7731 8.18173 12.0913 7.86355 12.0913 7.45446C12.0913 7.27264 12.2276 7.09082 12.4549 7.09082H26.6367C26.8185 7.09082 27.0004 7.22718 27.0004 7.45446C27.0004 7.86355 27.3185 8.18173 27.7276 8.18173C27.9094 8.18173 28.0913 8.31809 28.0913 8.54537V14.3635C28.0913 14.5454 27.9549 14.7272 27.7276 14.7272C27.3185 14.7272 27.0004 15.0454 27.0004 15.4545C27.0004 15.6817 26.864 15.8181 26.6822 15.8181ZM12.7731 15.1363H26.364C26.5004 14.6363 26.9094 14.2272 27.4094 14.0908V8.86355C26.9094 8.72718 26.5004 8.31809 26.364 7.81809H12.7731C12.6367 8.31809 12.2276 8.72718 11.7276 8.86355V14.0908C12.2276 14.2272 12.6367 14.6363 12.7731 15.1363Z"
                            fill="#030303"
                          />
                          <path
                            d="M9.68253 29.0907C8.36435 29.0907 7.27344 27.9998 7.27344 26.6362C7.27344 25.2725 8.36435 24.1816 9.68253 24.1816C11.0007 24.1816 12.0916 25.2725 12.0916 26.6362C12.0916 27.9998 11.0007 29.0907 9.68253 29.0907ZM9.68253 24.818C8.72798 24.818 7.95526 25.6362 7.95526 26.5907C7.95526 27.5453 8.72798 28.3635 9.68253 28.3635C10.6371 28.3635 11.4098 27.5453 11.4098 26.5907C11.4098 25.6362 10.6371 24.818 9.68253 24.818Z"
                            fill="#010101"
                          />
                          <path
                            d="M13.5437 34.0908V32.318C13.5437 31.1362 12.7255 30.1362 11.7255 30.1362H7.63459C6.63459 30.1362 5.81641 31.0908 5.81641 32.318V34.0908H13.5437Z"
                            fill="#F26869"
                          />
                          <path
                            d="M13.544 34.4544H5.81676C5.58949 34.4544 5.45312 34.2725 5.45312 34.0907V32.318C5.45312 30.9544 6.40767 29.818 7.63494 29.818H11.7259C12.9077 29.818 13.9077 30.9544 13.9077 32.318V34.0907C13.9077 34.2725 13.7713 34.4544 13.544 34.4544ZM6.13494 33.7725H13.2259V32.3635C13.2259 31.3635 12.544 30.5453 11.7259 30.5453H7.63494C6.81676 30.5453 6.13494 31.3635 6.13494 32.3635V33.7725ZM19.9986 29.0907C18.6804 29.0907 17.5895 27.9998 17.5895 26.6362C17.5895 25.2725 18.6804 24.1816 19.9986 24.1816C21.3168 24.1816 22.4077 25.2725 22.4077 26.6362C22.4077 27.9998 21.3168 29.0907 19.9986 29.0907ZM19.9986 24.818C19.044 24.818 18.2713 25.6362 18.2713 26.5907C18.2713 27.5453 19.044 28.3635 19.9986 28.3635C20.9531 28.3635 21.7259 27.5453 21.7259 26.5907C21.7259 25.6362 20.9531 24.818 19.9986 24.818Z"
                            fill="#010101"
                          />
                          <path
                            d="M23.864 34.0908V32.318C23.864 31.1362 23.0458 30.1362 22.0458 30.1362H17.9549C16.9549 30.1362 16.1367 31.0908 16.1367 32.318V34.0908H23.864Z"
                            fill="#D3D6DE"
                          />
                          <path
                            d="M23.8643 34.4544H16.1371C15.9553 34.4544 15.7734 34.318 15.7734 34.0907V32.318C15.7734 30.9544 16.728 29.818 17.9553 29.818H22.0462C23.228 29.818 24.228 30.9544 24.228 32.318V34.0907C24.228 34.2725 24.0916 34.4544 23.8643 34.4544ZM16.4553 33.7725H23.5462V32.3635C23.5462 31.3635 22.8643 30.5453 22.0462 30.5453H17.9553C17.1371 30.5453 16.4553 31.3635 16.4553 32.3635V33.7725ZM30.3189 29.0907C29.0007 29.0907 27.9098 27.9998 27.9098 26.6362C27.9098 25.2725 29.0007 24.1816 30.3189 24.1816C31.6371 24.1816 32.728 25.2725 32.728 26.6362C32.728 27.9998 31.6371 29.0907 30.3189 29.0907ZM30.3189 24.818C29.3643 24.818 28.5916 25.6362 28.5916 26.5907C28.5916 27.5453 29.3643 28.3635 30.3189 28.3635C31.2734 28.3635 32.0462 27.5453 32.0462 26.5907C32.0462 25.6362 31.2734 24.818 30.3189 24.818Z"
                            fill="#010101"
                          />
                          <path
                            d="M34.2273 34.0908V32.318C34.2273 31.1362 33.4091 30.1362 32.4091 30.1362H28.3182C27.3182 30.1362 26.5 31.0908 26.5 32.318V34.0908H34.2273Z"
                            fill="#8ECFF2"
                          />
                          <path
                            d="M34.2262 34.4547H26.4535C26.2717 34.4547 26.0898 34.3184 26.0898 34.0911V32.3184C26.0898 30.9547 27.0444 29.8184 28.2717 29.8184H32.3626C33.5444 29.8184 34.5444 30.9547 34.5444 32.3184V34.0911C34.5444 34.2729 34.408 34.4547 34.2262 34.4547ZM26.7717 33.7729H33.8626V32.3638C33.8626 31.3638 33.1808 30.5456 32.3626 30.5456H28.2717C27.4535 30.5456 26.7717 31.3638 26.7717 32.3638V33.7729Z"
                            fill="#010101"
                          />
                          <path
                            d="M19.5916 8.68164C18.0462 8.68164 16.7734 9.95437 16.7734 11.4998C16.7734 13.0453 18.0462 14.318 19.5916 14.318C21.1371 14.318 22.4098 13.0453 22.4098 11.4998C22.4098 9.95437 21.1371 8.68164 19.5916 8.68164Z"
                            fill="#FEDA7D"
                          />
                          <path
                            d="M19.5895 14.6365C17.8622 14.6365 16.4531 13.2274 16.4531 11.5001C16.4531 9.77286 17.8622 8.36377 19.5895 8.36377C21.3168 8.36377 22.7259 9.77286 22.7259 11.5001C22.7259 13.2274 21.3168 14.6365 19.5895 14.6365ZM19.5895 9.00013C18.2259 9.00013 17.1349 10.091 17.1349 11.4547C17.1349 12.8183 18.2259 13.9092 19.5895 13.9092C20.9531 13.9092 22.044 12.8183 22.044 11.4547C22.044 10.091 20.9531 9.00013 19.5895 9.00013Z"
                            fill="#F8AE5C"
                          />
                          <path
                            d="M19.5898 13.0907V12.7725C19.2262 12.7271 18.8626 12.5907 18.5898 12.318L18.908 11.9543C19.1353 12.1361 19.3626 12.2725 19.6353 12.318V11.5452C19.3171 11.4543 19.0898 11.3634 18.9535 11.2725C18.8171 11.1361 18.7262 10.9543 18.7262 10.7271C18.7262 10.4998 18.8171 10.318 18.9535 10.1361C19.1353 9.99978 19.3171 9.90887 19.5898 9.90887V9.72705H19.8626V9.95432C20.1808 9.95432 20.4535 10.0907 20.7262 10.2725L20.4535 10.6361C20.2717 10.4998 20.0898 10.4089 19.8626 10.4089V11.1816C20.1808 11.2725 20.408 11.3634 20.5444 11.4998C20.6808 11.6361 20.7717 11.818 20.7717 12.0452C20.7717 12.2725 20.6808 12.4543 20.4989 12.6361C20.3171 12.7725 20.0898 12.8634 19.8171 12.8634V13.1361H19.5898V13.0907ZM19.3171 10.4998C19.2262 10.5452 19.2262 10.6361 19.2262 10.7271C19.2262 10.818 19.2717 10.9089 19.3171 10.9543C19.3626 10.9998 19.4535 11.0452 19.6353 11.0907V10.3634C19.4535 10.3634 19.3626 10.4089 19.3171 10.4998ZM20.1353 12.2725C20.2262 12.2271 20.2717 12.1361 20.2717 12.0452C20.2717 11.9543 20.2262 11.8634 20.1808 11.818C20.1353 11.7725 19.9989 11.7271 19.8626 11.6816V12.4089C19.9989 12.3634 20.0898 12.318 20.1353 12.2725Z"
                            fill="#010101"
                          />
                          <path
                            d="M25.0909 12.591C25.6934 12.591 26.1818 12.1026 26.1818 11.5001C26.1818 10.8976 25.6934 10.4092 25.0909 10.4092C24.4884 10.4092 24 10.8976 24 11.5001C24 12.1026 24.4884 12.591 25.0909 12.591Z"
                            fill="#2AB675"
                          />
                          <path
                            d="M14.0909 12.591C14.6934 12.591 15.1818 12.1026 15.1818 11.5001C15.1818 10.8976 14.6934 10.4092 14.0909 10.4092C13.4884 10.4092 13 10.8976 13 11.5001C13 12.1026 13.4884 12.591 14.0909 12.591Z"
                            fill="#2AB675"
                          />
                          <path
                            d="M20.7724 21.0453L20.136 21.6816V18.5453C20.136 18.3635 19.9997 18.1816 19.7724 18.1816C19.5451 18.1816 19.4087 18.318 19.4087 18.5453V21.6816L18.7724 21.0453C18.636 20.9089 18.4087 20.9089 18.2724 21.0453C18.136 21.1816 18.136 21.4089 18.2724 21.5453L19.4997 22.7725C19.5451 22.818 19.5906 22.818 19.5906 22.8635C19.636 22.8635 19.6815 22.9089 19.7269 22.9089C19.7724 22.9089 19.8178 22.9089 19.8633 22.8635C19.9087 22.8635 19.9542 22.818 19.9542 22.7725L21.1815 21.5453C21.3178 21.4089 21.3178 21.1816 21.1815 21.0453C21.0451 20.9089 20.9087 20.9089 20.7724 21.0453ZM28.5906 20.4998C28.5906 20.318 28.4542 20.1362 28.2269 20.1362C27.9997 20.1362 27.8633 20.2725 27.8633 20.4998V21.4089L25.636 19.1816C25.4997 19.0453 25.2724 19.0453 25.136 19.1816C24.9997 19.318 24.9997 19.5453 25.136 19.6816L27.3633 21.9089H26.4542C26.2724 21.9089 26.0906 22.0453 26.0906 22.2725C26.0906 22.4998 26.2269 22.6362 26.4542 22.6362C28.9997 22.5907 28.5906 22.9998 28.5906 20.4998ZM14.4087 19.1816L12.1815 21.4089V20.4998C12.1815 20.318 12.0451 20.1362 11.8178 20.1362C11.5906 20.1362 11.4542 20.2725 11.4542 20.4998C11.4542 22.9998 10.9997 22.5453 13.4997 22.5453C13.6815 22.5453 13.8633 22.4089 13.8633 22.1816C13.8633 21.9544 13.7269 21.818 13.4997 21.818H12.5906L14.8178 19.5907C14.9542 19.4544 14.9542 19.2271 14.8178 19.0907C14.6815 18.9544 14.5451 19.0453 14.4087 19.1816Z"
                            fill="#030303"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1084_9943">
                            <rect
                              width="30"
                              height="30"
                              fill="white"
                              transform="translate(5 5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>

                      <div className="text_div">
                        <h6>Business Funding</h6>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={ScrollTop}
                      className="dropdown-item"
                      to={"/createrepair"}
                    >
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.50188 11.8389H34.4994C35.3056 11.8389 35.9544 12.3976 35.9544 13.0914V28.1176C35.9544 28.8126 35.3044 29.3714 34.4994 29.3714H4.50188C3.69563 29.3714 3.04688 28.8126 3.04688 28.1176V13.0926C3.04688 12.3976 3.69688 11.8389 4.50188 11.8389Z"
                          fill="#E9EDED"
                          stroke="#406B95"
                          stroke-width="0.929"
                        />
                        <path
                          d="M3.59375 13.7261V17.4761H35.91V13.7261H3.59375Z"
                          fill="#105286"
                        />
                        <path
                          d="M9.71172 26.2339H5.57422V27.4839H9.71172V26.2339Z"
                          fill="#CAD1D8"
                        />
                        <path
                          d="M29.8431 21.4001C29.8431 22.1314 29.5526 22.8328 29.0355 23.3499C28.5183 23.8671 27.817 24.1576 27.0856 24.1576C26.3543 24.1576 25.6529 23.8671 25.1358 23.3499C24.6186 22.8328 24.3281 22.1314 24.3281 21.4001C24.3281 20.6687 24.6186 19.9674 25.1358 19.4502C25.6529 18.9331 26.3543 18.6426 27.0856 18.6426C27.817 18.6426 28.5183 18.9331 29.0355 19.4502C29.5526 19.9674 29.8431 20.6687 29.8431 21.4001Z"
                          fill="#F8B84E"
                        />
                        <path
                          d="M15.2294 26.2336H11.0931V27.4836H15.2306L15.2294 26.2336ZM20.7444 26.2336H16.6081V27.4836H20.7456L20.7444 26.2336ZM26.2594 26.2336H22.1231V27.4836H26.2606L26.2594 26.2336ZM20.7444 20.0186H5.57812V22.5186H20.7444V20.0186Z"
                          fill="#CAD1D8"
                        />
                        <path
                          d="M33.1517 21.4001C33.1517 22.1314 32.8612 22.8328 32.3441 23.3499C31.8269 23.8671 31.1256 24.1576 30.3942 24.1576C29.6629 24.1576 28.9615 23.8671 28.4444 23.3499C27.9272 22.8328 27.6367 22.1314 27.6367 21.4001C27.6367 20.6687 27.9272 19.9674 28.4444 19.4502C28.9615 18.9331 29.6629 18.6426 30.3942 18.6426C31.1256 18.6426 31.8269 18.9331 32.3441 19.4502C32.8612 19.9674 33.1517 20.6687 33.1517 21.4001Z"
                          fill="#F05542"
                        />
                        <path
                          d="M34.3203 8.75H37.5016V32.6137H34.3203V8.75Z"
                          fill="#406B95"
                        />
                      </svg>

                      <div className="text_div">
                        <h6>Credit Repair</h6>
                      </div>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li
                className={
                  location.pathname === "/testimonials" ? "active" : ""
                }
              >
                <NavLink
                  onClick={ScrollTop}
                  className="nav-link"
                  to={"/testimonials"}
                >
                  Testimonials
                </NavLink>
              </li> */}
            </ul>
          </div>

          <NavLink onClick={ScrollTop} to={"/contactus"}>
            <Button>Contact Us</Button>
          </NavLink>
        </Container>    
      </nav>
    </>
  );
};

export default Nav;
