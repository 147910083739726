import React from "react";

export const ScrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

  return <></>;
};
